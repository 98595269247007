<template>
  <v-data-table
    dense
    :headers="headers"
    :footer-props="footer"
    :items="logsJson"
    :items-per-page="20"
    :loading=dataLoading
    :loading-text=loading
    multi-sort
  >
    <template v-slot:[`item.severity_msg`]="{ item }">
      <v-chip
        :color="getSeverityColor(item.severity_level)"
        dark
      >
        {{ item.severity_msg }}
      </v-chip>
    </template>
  </v-data-table>
</template>

<script>

export default {
  components: {
  },
  data () {
    return {
      headers: [
        { text: this.$t('t.ImportDataSources'), value: 'data_source', width: '5%' },
        { text: this.$t('t.Entity'), value: 'entity', width: '8%' },
        { text: this.$t('t.File'), value: 'file' },
        { text: this.$t('t.Column'), value: 'column' },
        { text: this.$t('t.Field'), value: 'field' },
        { text: this.$t('t.Line'), value: 'line' },
        { text: this.$t('t.Message'), value: 'msg' },
        { text: this.$t('t.Severity'), value: 'severity_msg' }
      ],
      footer: {
        itemsPerPageOptions: [20]
      },
      loading: this.$t('t.Loading'),
      dataLoading: true,
      logsJson: []
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler (id) {
        if (!id && this.duplicationInprogress) {
          this.duplicationInprogress = false
        } else {
          if (!this.documentId) {
            this.id = id
            this.refreshDocument()
          }
        }
      }
    }
  },
  computed: {
  },
  props: {
    disabled: Boolean,
    editAmountOnly: Boolean
  },
  methods: {
    async refreshDocument () {
      const id = '1c859420-6fa7-445f-8f15-fd46b0f9ba77'
      const r = await this.$http().get('/core/import/log/' + id)
      this.logsJson = r.data
      this.dataLoading = false
    },
    getSeverityColor (severity) {
      if (severity === 0) return 'red'
      else if (severity === 1) return 'orange'
      else return 'purple'
    }
  }
}
</script>

<style lang="stylus" scoped>
>>> tr
  cursor pointer
</style>
